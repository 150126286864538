/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { Rules } from '../../../helpers/RHFRules';
import CustomCheck from '../../form/CustomCheck';
import CustomSelect from '../../form/CustomSelect';

const useStyles = makeStyles((theme) => ({
  typeInput: {
    '& .MuiOutlinedInput-root': {
      // height: '40px',
      position: 'relative',
      borderRadius: '10px',
      boxShadow: '1px 5px 5px #d7d7d7',
    },
  },
  termsAndConditions: {
    fontSize: 14,
  },
  policyAndTerms: {
    ...theme.registerForm.activatePlan.policyAndTerms,
    fontSize: 14,
    marginLeft: 3,
  },
  labelInTextField: {
    textAlign: 'left',
    width: '100%',
    color: 'red',
  },
  formErrors: {
    color: theme.palette.error.main,
    fontSize: 14,
  },
}));

const ValidationBriometicData = forwardRef((props, ref) => {
  const { functionOnSubmit, urlsFromBack } = props;
  const clasess = useStyles();
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const typeDocument = [{ value: 'CC', label: 'Cedula Ciudadanía' }];

  const getValuesForm = () => ({
    documentType: getValues('typeDocument'),
    document: getValues('documentNumber'),
    email: getValues('email'),
    phoneNumber: getValues('phoneNumber'),
  });

  useImperativeHandle(ref, () => ({
    getValuesForm,
  }));

  return (
    <form
      id="formValidationData"
      noValidate
      onSubmit={handleSubmit(functionOnSubmit)}
      style={{ marginTop: 25, marginBottom: 15 }}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <CustomSelect
            label="Tipo de documento"
            name="typeDocument"
            options={typeDocument}
            defaultValue="CC"
            control={control}
            required
            rule={Rules.required}
            error={errors}
            styleFromHelperText={{ style: { marginRight: 10 } }}
            // inputProps={{ onChange: (e) => setPersonType(e) }}
            classes={clasess.typeInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="documentNumber"
            control={control}
            rules={Rules.otrosDocumentos}
            error={Boolean(errors.documentNumber)}
            as={(
              <TextField
                id="documentNumber"
                variant="outlined"
                label="Número de documento*"
                autoComplete="off"
                error={!!errors.documentNumber}
                helperText={errors.documentNumber?.message}
                className={clasess.typeInput}
                inputProps={{ maxLength: 15 }}
                fullWidth
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            control={control}
            rules={Rules.emailLessComplex}
            error={Boolean(errors.email)}
            as={(
              <TextField
                variant="outlined"
                label="Correo electrónico*"
                autoComplete="off"
                error={!!errors.email}
                helperText={errors.email?.message}
                className={clasess.typeInput}
                fullWidth
                size="small"
                inputProps={{ maxLength: 100 }}
              />
          )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={Rules.telCel10Digits}
            error={Boolean(errors.phoneNumber)}
            as={(
              <TextField
                inputProps={{
                  maxLength: 10,
                }}
                type="tel"
                variant="outlined"
                label="Teléfono / Celular*"
                autoComplete="off"
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                className={clasess.typeInput}
                fullWidth
                InputLabelProps={clasess.labelInTextField}
                size="small"
              />
          )}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomCheck
            label={(
              <span>
                Acepto
                <a
                  href={
                    urlsFromBack !== undefined
                      ? urlsFromBack?.urlPersonalDataTreatmentPolicy
                      : '#'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={clasess.policyAndTerms}
                >
                  Política de Tratamiento de Datos Personales
                </a>
              </span>
            )}
            name="legalTermsCheck"
            control={control}
            rule={Rules.checkTerminosCondiciones}
            error={errors}
            classesFormControlLabel={{ label: clasess.termsAndConditions }}
            size="small"
            // disabled={disabledToSave}
            defaultValue={false}
          />
          {errors.legalTermsCheck && (
            <Typography className={clasess.formErrors}>
              {errors.legalTermsCheck.message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </form>
  );
});

export default ValidationBriometicData;
